import React from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import gql from "graphql-tag";
import { compose, graphql } from "react-apollo";
import Loading from "sections/Loading";

import { connect } from "react-redux";
import LogInButton from "components/LogInButton";

const GET_FOLLOWEES = gql`
  query GetFollowee($handle: String!) {
    followee(handle: $handle) {
      handle
      is_followed_by_me

      avatar {
        ... on Media {
          hashid
          is_processed
          square_image_sources {
            url
            width
          }
          type
        }
        ... on Url {
          url
        }
      }
    }
  }
`;

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

class Following extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      followees: [],
      logInButtonHandler: null,
      handleCloseModal: null,
      isModalShowed: false,
    };
  }

  setLogInButtonHandler = (handler) => {
    this.setState({ logInButtonHandler: handler });
  };
  setHandleCloseModalHandler = (handler) => {
    this.setState({ handleCloseModal: handler });
  };

  handleSignInClickInFirstComponent = () => {
    if (this.state.logInButtonHandler) {
      this.setState({ isModalShowed: true });
      this.state.logInButtonHandler();
    }
  };

  componentDidMount() {
    if (!this.props.data.loading && this.props.data.followees) {
      const updatedFollowees = this.props.data.followees.map((user) => ({
        ...user,
        isFollowed: false,
      }));
      this.setState({ followees: updatedFollowees });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.data.loading && prevProps.data !== this.props.data) {
      const updatedFollowees = this.props.data.followee.map((user) => ({
        ...user,
        isFollowed: false,
      }));
      this.setState({ followees: updatedFollowees });
    }
    if (
      this.props.authenticated &&
      this.state.handleCloseModal &&
      this.state.isModalShowed
    ) {
      this.state.handleCloseModal();
      this.props.data.refetch();
      this.setState({ isModalShowed: false });
    }
  }

  handleFollow = (index) => {
    const updatedFollowees = [...this.state.followees];
    updatedFollowees[index].isFollowed = true;
    this.setState({ followees: updatedFollowees });
  };

  handleUnfollow = (index) => {
    const updatedFollowees = [...this.state.followees];
    updatedFollowees[index].isFollowed = false;
    this.setState({ followees: updatedFollowees });
  };

  render() {
    // const { match, data } = this.props;
    // const { hashid } = match.params;

    if (this.props.data.loading) {
      return <Loading />;
    }

    const { followees } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>Following</div>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>Followers</div>

          <div className={styles.content}>
            <div className={styles.items}>
              {followees.map((user, index) => (
                <div key={index} className={styles.userCardWrapper}>
                  <div className={styles.avatarAndHandleWrap}>
                    {user.avatar.square_image_sources ? (
                      <img
                        src={user.avatar.square_image_sources[0].url}
                        className={styles.userAvatar}
                      />
                    ) : (
                      <img
                        src={user.avatar.url}
                        className={styles.userAvatar}
                      />
                    )}
                    <div className={styles.userHandle}>{user.handle}</div>
                  </div>
                  {user.is_followed_by_me ? (
                    <button
                      className={styles.unfollowBtn}
                      onClick={() => this.handleUnfollow(index)}
                    >
                      Unfollow
                    </button>
                  ) : (
                    <button
                      className={styles.followBtn}
                      onClick={() =>
                        this.props.authenticated
                          ? this.handleFollow(index)
                          : this.handleSignInClickInFirstComponent()
                      }
                    >
                      Follow
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <LogInButton
          isButtonHidden
          handleSignInClickRef={this.setLogInButtonHandler}
          handleCloseModalRef={this.setHandleCloseModalHandler}
        />
      </div>
    );
  }
}

Following.propTypes = {
  authenticated: PropTypes.bool, // Auth reducer
  data: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    refetch: PropTypes.func.isRequired,
  }),
  match: PropTypes.object.isRequired,
};

Following.defaultProps = {
  authenticated: false,
};

export default compose(
  graphql(GET_FOLLOWEES, {
    options: (props) => ({
      variables: {
        handle: props.match.params.hashid,
      },
    }),
  }),
  connect(mapStateToProps, null)
)(Following);
