import classNames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import Modal from "components/Modal";
import Icon from "components/Icon";

import * as authActions from "actions/auth";
import store from "~/store";
import { getAppToken } from "~/auth";

// eslint-disable-next-line import/no-nodejs-modules
import handleRegex, {
  html5String as html5HandleRegex,
} from "constants/handleRegex";

import styles from "./style.scss";

import {
  hasProbablyLoggedInBefore,
  initializeHello,
  initiateEmailRegistration,
  initiatePasswordReset,
  login,
  loginEmail,
} from "../../auth";
import PasswordInput from "../PasswordInput";

class LogInButton extends React.Component {
  static propTypes = {
    authRequestPending: PropTypes.bool, // Auth reducer
    handleSignInClickRef: PropTypes.func,
    handleCloseModalRef: PropTypes.func,
    isButtonHidden: PropTypes.bool,
  };

  static defaultProps = {
    isButtonHidden: false,
  };

  getOpenSignup() {
    if (
      localStorage["Referral-openSignup"] &&
      localStorage["Referral-openSignup"] === "true"
    ) {
      return true;
    }

    return false;
  }

  constructor() {
    super();

    this.state = {
      email: "",
      isCheckboxChecked: true,
      firstPartyLoginError: null,
      firstPartyRegistrationError: null,
      firstPartyRegistrationSuccess: false,
      forgotPasswordError: null,
      forgotPasswordSuccess: false,
      showForgotPassword: false,
      showModal: this.getOpenSignup(),
      showRegistration: !hasProbablyLoggedInBefore(),
      isPasswordShowed: false,
    };
  }

  componentDidMount() {
    if (this.props.handleSignInClickRef) {
      this.props.handleSignInClickRef(this.handleSignInClick);
    }
    if (this.props.handleCloseModalRef) {
      this.props.handleCloseModalRef(this.handleCloseModal);
    }
  }

  handleDisplayPassword = () => {
    this.setState((prevState) => ({
      isPasswordShowed: !prevState.isPasswordShowed,
    }));
  };

  handleOpenModal = () => {
    this.setState({
      firstPartyLoginError: null,
      firstPartyRegistrationError: null,
      firstPartyRegistrationSuccess: false,
      forgotPasswordError: null,
      forgotPasswordSuccess: false,
      showForgotPassword: false,
      showModal: true,
      showRegistration: !hasProbablyLoggedInBefore(),
    });

    // Preemptively initialize Hello
    initializeHello();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.authRequestPending && nextProps.authRequestPending) {
      store.dispatch(authActions.authRequestResolved());
      this.handleOpenModal();
    }
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  handleEmailOrHandleInput = (event) => {
    let input = event.target;
    if (input.validity.patternMismatch) {
      input.setCustomValidity("Please enter an email address or handle.");
    } else {
      input.setCustomValidity("");
    }
  };

  handleLogin = (provider) => {
    login(provider);
  };

  handleFacebook = () => {
    return this.handleLogin("facebook");
  };

  handleGoogle = () => {
    return this.handleLogin("google");
  };

  handleTwitter = () => {
    return this.handleLogin("twitter");
  };

  handleToggleRegistration = () => {
    this.setState({
      showForgotPassword: false,
      showRegistration: !this.state.showRegistration,
    });
  };

  handleToggleForgotPassword = () => {
    this.setState({
      showForgotPassword: !this.state.showForgotPassword,
    });
  };

  handleFirstPartyLoginSubmission = (event) => {
    event.preventDefault();

    let form = this.firstPartyLoginForm;
    // Retrieve values
    let formData = new FormData(form);

    // If email looks like a handle, treat it as such
    let email = formData.get("email");
    if (handleRegex.test(email)) {
      formData.append("handle", email);
      formData.delete("email");
    }
    // Register mobile app token
    const appToken = getAppToken();
    if (appToken) {
      formData.append("app_token", appToken);
    }
    // Sign in
    loginEmail(formData).catch((error) => {
      if (error.name === "ValidationError") {
        // TODO: use error.data and a language map to show messages
      }
      this.setState({
        firstPartyLoginError: error,
      });
    });
  };

  handleFirstPartyRegistrationSubmission = (event) => {
    event.preventDefault();

    let form = this.firstPartyRegistrationForm;
    // Retrieve values
    let formData = new FormData(form);
    // Register
    initiateEmailRegistration(formData)
      .catch((error) => {
        if (error.name === "ValidationError") {
          // TODO: use error.data and a language map to show messages
        }
        this.setState({
          firstPartyRegistrationError: error,
          firstPartyRegistrationSuccess: false,
        });
      })
      .then(() => {
        this.setState({
          firstPartyRegistrationError: null,
          firstPartyRegistrationSuccess: true,
        });
      });
  };

  handleForgotPasswordSubmission = (event) => {
    event.preventDefault();

    let form = this.forgotPasswordForm;

    // Retrieve values
    let formData = new FormData(form);

    // Send request
    initiatePasswordReset(formData)
      .catch((error) => {
        if (error.name === "ValidationError") {
          // TODO: use error.data and a language map to show messages
        }
        this.setState({
          forgotPasswordError: error,
          forgotPasswordSuccess: false,
        });
        throw error;
      })
      .then(() => {
        this.setState({
          forgotPasswordError: null,
          forgotPasswordSuccess: true,
        });
      });
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  handleSignInClick = () => {
    this.scrollToTop();
    this.handleOpenModal();
  };

  render() {
    return (
      <div>
        {!this.props.isButtonHidden && (
          <Button
            size="sm"
            color="primary"
            onClick={this.handleSignInClick}
            className={classNames(styles.button, styles.signInSignUpBtn)}
          >
            Sign In/Sign Up
          </Button>
        )}
        <Modal
          contentLabel="Sign In"
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          extraPadding={true}
        >
          <div className={classNames(styles.wrapper)}>
            <div className={classNames(styles.container)}>
              <h2 className={classNames(styles.head)}>
                {this.state.showRegistration
                  ? "Sign-Up Email"
                  : this.state.showForgotPassword
                  ? "Reset Password"
                  : "Sign In"}
              </h2>

              {/* Form place */}
              {this.state.showRegistration
                ? this.renderFirstPartyRegistrationPanel()
                : this.state.showForgotPassword
                ? this.renderForgotPasswordPanel()
                : this.renderFirstPartyLoginPanel()}
              {!this.state.firstPartyRegistrationSuccess && (
                <div>
                  <div className={classNames(styles.BlockWithLines)}>
                    <div className={classNames(styles.line)} />
                    <div
                      className={classNames(
                        styles.text,
                        styles.defaultText,
                        styles.color172331
                      )}
                    >
                      {this.state.showRegistration ||
                      this.state.showForgotPassword
                        ? "Sign-up with"
                        : "Or log in with"}
                    </div>
                    <div className={classNames(styles.line)} />
                  </div>
                  <div className={classNames(styles.loginIconsContainer)}>
                    <button
                      className={classNames(styles.loginWithGoogle)}
                      onClick={this.handleGoogle}
                    >
                      <Icon icon="loginWithGoogle" />
                    </button>
                  </div>
                  <div
                    className={classNames(
                      styles.defaultText,
                      styles.color000000
                    )}
                  >
                    {this.state.showRegistration ||
                    this.state.showForgotPassword
                      ? "Already have an account?"
                      : "Don’t have an account?"}

                    <span
                      className={classNames(styles.colorf75a00)}
                      onClick={this.handleToggleRegistration}
                    >
                      {this.state.showRegistration ||
                      this.state.showForgotPassword
                        ? " Sign In"
                        : " Sign Up"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* \/ old code \/ */}
          {/* <div className={classNames(styles.modalInner)}>
            <h1
              className={classNames(modalStyles.titleMain, "title", "title-md")}
            >
              {this.state.showRegistration ? "Sign Up to Sparkit" : "Sign In"}
            </h1>

            <div className={classNames(modalStyles.section)}>
              {socialLogins.twitter === "on" && (
                <Button size="lg" color="tertiary" onClick={this.handleTwitter}>
                  Twitter
                </Button>
              )}
              {socialLogins.facebook === "on" && (
                <Button
                  size="lg"
                  color="tertiary"
                  onClick={this.handleFacebook}
                >
                  Facebook
                </Button>
              )}
              {socialLogins.google === "on" && (
                <Button size="lg" color="tertiary" onClick={this.handleGoogle}>
                  Google
                </Button>
              )}
            </div>

            <div className={classNames(styles.separator)}>
              <h2
                className={classNames(
                  modalStyles.title,
                  styles.separatorTitle,
                  "title",
                  "title-sm"
                )}
              >
                Or with Email
              </h2>
            </div>
            {this.renderFirstPartyPanel()}

            <h2 className={classNames(modalStyles.title, "title", "title-sm")}>
              {this.state.showRegistration
                ? "Already have an account?"
                : "Don't have an account?"}
            </h2>
            <Button
              size="sm-wide"
              color="primary"
              className={classNames(styles.button)}
              onClick={this.handleToggleRegistration}
            >
              {this.state.showRegistration ? "Sign In" : "Sign Up"}
            </Button>
          </div> */}
        </Modal>
      </div>
    );
  }

  renderFirstPartyPanel = () => {
    if (this.state.showForgotPassword) {
      return this.renderForgotPasswordPanel();
    }
    if (this.state.showRegistration) {
      return this.renderFirstPartyRegistrationPanel();
    }
    return this.renderFirstPartyLoginPanel();
  };

  renderForgotPasswordPanel = () => {
    if (this.state.forgotPasswordSuccess) {
      return (
        <div className={styles.hintBlock}>
          If we have that email address on file we’ve sent an email to it. You
          can follow the link in that email to reset your password.
        </div>
      );
    }
    return (
      <form
        className={classNames(styles.form)}
        ref={(ref) => (this.forgotPasswordForm = ref)}
        onSubmit={this.handleForgotPasswordSubmission}
      >
        <div className={classNames(styles.inputBlock, styles.marginBottom)}>
          <span className={classNames(styles.renderForgotPasswordPanelText)}>
            Request a password reset to email
          </span>
          <div className={classNames(styles.inputContainer)}>
            <div className={classNames(styles.iconWrapper)}>
              <Icon icon="letter" size="sm" />
            </div>
            <input
              type="text"
              pattern={
                html5HandleRegex +
                "|[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*"
              }
              inputMode="email"
              name="email"
              value={this.state.email}
              placeholder="Email or handle"
              required
              onChange={this.handleEmailChange}
              onInput={this.handleEmailOrHandleInput}
              className={classNames(styles.input)}
            />
          </div>
        </div>

        {/* <PasswordInput isHidden /> */}
        <div className={classNames(styles.checkboxGroup, styles.hiddenBlock)}>
          <div className={classNames(styles.customCheckboxContainer)}>
            <div
              className={classNames(styles.customCheckbox, {
                [styles.checkboxActive]: this.state.isCheckboxChecked === true,
              })}
            >
              <div className={classNames(styles.checkmark)}></div>
            </div>
            <span className={classNames(styles.checkboxText)}>Remember me</span>
          </div>
          <button
            onClick={this.handleToggleForgotPassword}
            type="button"
            className={classNames(
              styles.buttonText,
              styles.italicText,
              styles.colorf75a00
            )}
          >
            Forgot Password?
          </button>
        </div>

        <button
          type="submit"
          className={classNames(styles.loginButton, styles.loginButtonOutlined)}
        >
          Next
        </button>
        {this.state.forgotPasswordError ? (
          <ErrorMessage error={this.state.forgotPasswordError} />
        ) : null}
      </form>
    );
  };

  renderFirstPartyLoginPanel = () => {
    return (
      <form
        className={classNames(styles.form)}
        ref={(ref) => (this.firstPartyLoginForm = ref)}
        onSubmit={this.handleFirstPartyLoginSubmission}
      >
        <div className={classNames(styles.inputBlock)}>
          <span className={classNames(styles.formText)}>Email</span>
          <div className={classNames(styles.inputContainer)}>
            <div className={classNames(styles.iconWrapper)}>
              <Icon icon="letter" size="sm" />
            </div>
            <input
              type="text"
              pattern={
                html5HandleRegex +
                "|[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*"
              }
              inputMode="email"
              name="email"
              value={this.state.email}
              placeholder="Email or handle"
              required
              onChange={this.handleEmailChange}
              onInput={this.handleEmailOrHandleInput}
              className={classNames(styles.input)}
            />
          </div>
        </div>

        <PasswordInput className={styles.passwordInputWrapper} />

        <div className={classNames(styles.checkboxGroup)}>
          <div className={classNames(styles.customCheckboxContainer)}>
            <div
              className={classNames(styles.customCheckbox, {
                [styles.checkboxActive]: this.state.isCheckboxChecked === true,
              })}
            >
              <div className={classNames(styles.checkmark)}></div>
            </div>
            <span className={classNames(styles.checkboxText)}>Remember me</span>
          </div>
          <button
            onClick={this.handleToggleForgotPassword}
            type="button"
            className={classNames(
              styles.buttonText,
              styles.italicText,
              styles.colorf75a00
            )}
          >
            Forgot Password?
          </button>
        </div>

        <button
          type="submit"
          className={classNames(
            styles.loginButton,
            styles.loginButtonContained
          )}
        >
          Log In
        </button>
        {this.state.firstPartyLoginError ? (
          <ErrorMessage error={this.state.firstPartyLoginError} />
        ) : null}
      </form>

      // Old code

      // <div className={classNames(modalStyles.section)}>
      //   <form >
      //     <input
      //       type="text"
      //       pattern={
      //         html5HandleRegex +
      //         "|[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*"
      //       }
      //       inputMode="email"
      //       name="email"
      //       value={this.state.email}
      //       placeholder="Email or handle"
      //       required
      //       onChange={this.handleEmailChange}
      //       onInput={this.handleEmailOrHandleInput}
      //     />
      //     <input
      //       type="password"
      //       name="password"
      //       required
      //       placeholder="Password"
      //     />
      //     <Button size="lg" color="tertiary" type="submit">
      //       Submit
      //     </Button>
      //     {this.state.firstPartyLoginError ? (
      //       <ErrorMessage error={this.state.firstPartyLoginError} />
      //     ) : null}
      //   </form>

      //   <Button color="underline" onClick={this.handleToggleForgotPassword}>
      //     Forgot your password?
      //   </Button>
      // </div>
    );
  };

  renderFirstPartyRegistrationPanel = () => {
    if (this.state.firstPartyRegistrationSuccess) {
      return (
        <div className={styles.hintBlock}>
          You should soon receive an email at the address you gave with
          instructions on how to complete registration.
        </div>
      );
    }
    return (
      <form
        className={classNames(styles.form)}
        ref={(ref) => (this.firstPartyRegistrationForm = ref)}
        onSubmit={this.handleFirstPartyRegistrationSubmission}
      >
        <div className={classNames(styles.inputBlock, styles.marginBottom)}>
          <span className={classNames(styles.formText)}>Email</span>
          <div className={classNames(styles.inputContainer)}>
            <div className={classNames(styles.iconWrapper)}>
              <Icon icon="letter" size="sm" />
            </div>
            <input
              type="text"
              pattern={
                html5HandleRegex +
                "|[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*"
              }
              inputMode="email"
              name="email"
              value={this.state.email}
              placeholder="Email or handle"
              required
              onChange={this.handleEmailChange}
              onInput={this.handleEmailOrHandleInput}
              className={classNames(styles.input)}
            />
          </div>
        </div>

        {/* <PasswordInput isHidden /> */}
        <div className={classNames(styles.checkboxGroup, styles.hiddenBlock)}>
          <div className={classNames(styles.customCheckboxContainer)}>
            <div
              className={classNames(styles.customCheckbox, {
                [styles.checkboxActive]: this.state.isCheckboxChecked === true,
              })}
            >
              <div className={classNames(styles.checkmark)}></div>
            </div>
            <span className={classNames(styles.checkboxText)}>Remember me</span>
          </div>
          <button
            onClick={this.handleToggleForgotPassword}
            type="button"
            className={classNames(
              styles.buttonText,
              styles.italicText,
              styles.colorf75a00
            )}
          >
            Forgot Password?
          </button>
        </div>

        <button
          type="submit"
          className={classNames(styles.loginButton, styles.loginButtonOutlined)}
        >
          Next
        </button>
        {this.state.firstPartyRegistrationError ? (
          <ErrorMessage error={this.state.firstPartyRegistrationError} />
        ) : null}
      </form>
    );
  };
}

const mapStateToProps = function (state) {
  return {
    authRequestPending: state.auth.authRequestPending,
  };
};

const ConnectedLogInButton = connect(mapStateToProps)(LogInButton);

export default ConnectedLogInButton;
