// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-SparkCards-style__root__K4DOW{width:100%;max-width:100vw}.components-SparkCards-style__grid__kYnf0>div{gap:20px}.components-SparkCards-style__grid__kYnf0>div>div{margin-left:0}.components-SparkCards-style__loading__vQFTQ{margin-top:20px;margin-bottom:20px}.components-SparkCards-style__loadMoreBtn__e8JYo{margin-bottom:20px;border-radius:10px;background-color:#f75a00;-webkit-box-shadow:0px 8px 24px 0px rgba(247,90,0,.25);box-shadow:0px 8px 24px 0px rgba(247,90,0,.25);height:50px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.components-SparkCards-style__grayBackground__eWiVU{background-color:#f9f9f9}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-SparkCards-style__root__K4DOW`,
	"grid": `components-SparkCards-style__grid__kYnf0`,
	"loading": `components-SparkCards-style__loading__vQFTQ`,
	"loadMoreBtn": `components-SparkCards-style__loadMoreBtn__e8JYo`,
	"grayBackground": `components-SparkCards-style__grayBackground__eWiVU`
};
export default ___CSS_LOADER_EXPORT___;
