import {
  CHANGE_INPUT_VISIBILITY,
  SEARCH_VALUE_CHANGE,
  SEARCH,
  SET_VALUES,
  IFRAME_PAGE,
} from "actions/hashtagSearch";

const initialState = {
  searchValue: "",
  tags: [],
  isInputHidden: true,
  isIframePage: false,
};

const hashtagReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_INPUT_VISIBILITY:
      return {
        ...state,
        isInputHidden: action.payload,
      };
    case SEARCH_VALUE_CHANGE: {
      let updatedValue = action.payload;
      const words = action.payload.split(" ");
      updatedValue = words
        .map((word) => {
          if (word.trim() !== "" && !word.startsWith("#")) {
            return `#${word}`;
          }
          return word;
        })
        .join(" ");
      return {
        ...state,
        searchValue: updatedValue,
      };
    }

    case SEARCH: {
      if (state.isInputHidden) {
        return {
          ...state,
          isInputHidden: false,
        };
      }
      const { searchValue } = state;
      const updatedTags = searchValue.split(" ").map((tag) => {
        return tag.startsWith("#") ? tag.slice(1) : tag;
      });
      const hasTags = updatedTags.some((tag) => tag.trim() !== "");

      const queryParams = hasTags ? `hashtags=${updatedTags.join(",")}` : "";

      const baseUrl = window.location.origin + window.location.pathname;

      const updateHistoryAndState = () => {
        const path = queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
        const isMainPage =
          window.location.pathname === "/" ||
          /^\/\?/.test(window.location.pathname);
        if (isMainPage) {
          window.history.pushState({ path }, "", path);
        } else {
          window.location.href = `/?${queryParams}`;
        }

        return {
          ...state,
          tags: updatedTags,
          searchValue: "",
        };
      };

      if (!searchValue || !hasTags) {
        return updateHistoryAndState();
      }
      updateHistoryAndState();
      return {
        ...state,
        searchValue: "",
        tags: updatedTags,
      };
    }
    case SET_VALUES:
      return {
        ...state,
        // searchValue: action.payload.searchValue.searchValue,
        tags: action.payload.searchValue.tags,
      };
    case IFRAME_PAGE:
      return {
        isIframePage: true,
      };
    default:
      return state;
  }
};

export default hashtagReducer;
