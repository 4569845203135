import classNames from "classnames";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";

// import Button from "components/Button";
import Loading from "components/Loading";
import ResponsiveGrid from "components/ResponsiveGrid";
import Grid from "components/Grid";
import SparkCard from "components/SparkCard";

import { refetchHandles } from "~/auth";

import styles from "./style.scss";
import { iframePage } from "actions/hashtagSearch";

import { connect } from "react-redux";

const mapStateToProps = function (state) {
  return {
    tagsState: state.hashtag.tags,
    isIframePage: state.hashtag.isIframePage,
  };
};

const mapDispatchToProps = {
  onIframePage: iframePage,
};

class DeltaIceHawksSparkCards extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    deltaIceHawksPage: PropTypes.bool,
    isProfile: PropTypes.bool,
    tagsState: PropTypes.array,
    data: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
      error: PropTypes.object,
      loadMoreEntries: PropTypes.func,
      loading: PropTypes.bool.isRequired,
      sparks: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.object.isRequired,
          })
        ).isRequired,
        pageInfo: PropTypes.shape({
          hasNextPage: PropTypes.bool.isRequired,
        }).isRequired,
      }),
    }),
    responsive: PropTypes.bool,
    user: PropTypes.string,
    tags: PropTypes.array,
  };

  static defaultProps = {
    className: "",
    isProfile: false,
    responsive: true,
    user: "",
    deltaIceHawksPage: false,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      currentDate: null,
    };
  }

  componentDidMount() {
    const currentDate = Math.floor(Date.now() / 1000);
    this.setState({ currentDate });
    localStorage.setItem("isDeltaIceHawksIframe", "true");

    this.props.onIframePage();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.data) {
      refetchHandles.sparkCardsHandle = props.data;
    }
  }

  getDeltaIceHawksIframeStatus() {
    return localStorage.getItem("isDeltaIceHawksIframe") === "true";
  }

  render() {
    const { className, data, responsive, isProfile } = this.props;

    const targetWidth = 400;
    let sparks = null;

    if (data.sparks) {
      sparks = data.sparks.edges.map((edge) => {
        return (
          <SparkCard
            isProfile={isProfile}
            key={edge.node.hashid}
            spark={edge.node}
            currentDate={this.state.currentDate}
            targetWidth={targetWidth}
          />
        );
      });
    }

    if (data.error) {
      return <p>Error</p>;
    }

    // console.log("SparkCards data ->", this.state.data.sparks.edges);

    return (
      <div
        className={classNames(styles.root, className, styles.grid, {
          [styles.grayBackground]: this.props.deltaIceHawksPage,
        })}
      >
        {sparks && responsive && (
          <ResponsiveGrid targetWidth={targetWidth}>{sparks}</ResponsiveGrid>
        )}

        {sparks && !responsive && <Grid>{sparks}</Grid>}

        {data.loading && !sparks && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}

        {/* {!this.props.deltaIceHawksPage &&
          data.sparks &&
          data.sparks.pageInfo.hasNextPage &&
          data.loadMoreEntries && (
            <div className={styles.loadMore}>
              <Button
                size="lg"
                onClick={data.loadMoreEntries}
                disabled={data.loading}
                className={styles.loadMoreBtn}
              >
                Load more
                {data.loading && (
                  <span>
                    {" "}
                    <Loading inline color="white" size="sm" />
                  </span>
                )}
              </Button>
            </div>
          )} */}
      </div>
    );
  }
}

export const SPARKS_QUERY = gql`
  query SparksForHome($cursor: String, $user: String, $tags: [String!]) {
    sparks(first: 4, after: $cursor, user: $user, tags: $tags) {
      pageInfo {
        endCursor
        hasNextPage
        __typename
      }
      edges {
        cursor
        __typename
        node {
          closes_at
          created_at
          has_closing_comment
          hashid
          permalink
          status
          location {
            name
            place_id
            __typename
          }
          tags
          closing_comment {
            hashid
            created_at
            parent {
              hashid
              created_at
              is_voted_on_by_me
              message
              permalink
              votes_count
              user {
                handle
                hashid
              }
            }
          }
          opening_comment {
            hashid
            media {
              hashid
              is_processed
              square_image_sources {
                url
                width
              }
              type
              __typename
            }
            message
            __typename
          }
          tags
          user {
            avatar {
              ... on Media {
                hashid
                is_processed
                square_image_sources {
                  url
                  width
                }
                type
                __typename
              }
              ... on Url {
                url
                __typename
              }
            }
            handle
            hashid
            __typename
          }
          __typename
        }
      }
      __typename
    }
  }
`;

const DeltaIceHawksSparkCardsWithData = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(SPARKS_QUERY, {
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        cursor: null,
        user: "DeltaIceHawks",
        // user: "Gatorcount",
        tags: props.tagsState
          ? props.tagsState.length <= 0
            ? []
            : props.tagsState[0] === ""
            ? []
            : props.tagsState
          : [],
      },
    }),
    props: (props) => {
      props.data.loadMoreEntries = () => {
        return props.data.fetchMore({
          query: SPARKS_QUERY,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.sparks.edges;
            const pageInfo = fetchMoreResult.sparks.pageInfo;

            return {
              // Put new results at the end of the list, update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              sparks: {
                __typename: previousResult.sparks.__typename,
                edges: [...previousResult.sparks.edges, ...newEdges],
                pageInfo,
              },
            };
          },
          variables: {
            cursor: props.data.sparks.pageInfo.endCursor,
            user: props.data.variables.user,
          },
        });
      };
      return props;
    },
  })
)(DeltaIceHawksSparkCards);

export default DeltaIceHawksSparkCardsWithData;
