// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-DeltaIceHawksSparkCards-style__root__acmsb{width:100%;max-width:100vw}.components-DeltaIceHawksSparkCards-style__grid__ch0M2>div{gap:20px}.components-DeltaIceHawksSparkCards-style__grid__ch0M2>div>div{margin-left:0}.components-DeltaIceHawksSparkCards-style__loading__hDpaE{margin-top:20px;margin-bottom:20px}.components-DeltaIceHawksSparkCards-style__loadMoreBtn__SK1c2{margin-bottom:20px;border-radius:10px;background-color:#f75a00;-webkit-box-shadow:0px 8px 24px 0px rgba(247,90,0,.25);box-shadow:0px 8px 24px 0px rgba(247,90,0,.25);height:50px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.components-DeltaIceHawksSparkCards-style__grayBackground__IvAMx{background-color:#f9f9f9}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-DeltaIceHawksSparkCards-style__root__acmsb`,
	"grid": `components-DeltaIceHawksSparkCards-style__grid__ch0M2`,
	"loading": `components-DeltaIceHawksSparkCards-style__loading__hDpaE`,
	"loadMoreBtn": `components-DeltaIceHawksSparkCards-style__loadMoreBtn__SK1c2`,
	"grayBackground": `components-DeltaIceHawksSparkCards-style__grayBackground__IvAMx`
};
export default ___CSS_LOADER_EXPORT___;
